import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/es5/locale/en';
import de from 'vuetify/es5/locale/de';
import it from 'vuetify/es5/locale/it';

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0594D4',
        secondary: '#33e39a',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        white: '#ffffff',
      },
      dark: {
        primary: '#0594D4',
        secondary: '#33e39a',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        white: '#ffffff',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  lang: {
    locales: { en, de, it },
    current: 'de',
  },
});
