import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue')
  },
  {
    path: '/member',
    name: 'member',
    // component: () => import(/* webpackChunkName: "member" */ '../views/Insurance.vue')
    component: () => import(/* webpackChunkName: "member" */ '../views/Member.vue')
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import(/* webpackChunkName: "insurance" */ '../views/Insurance.vue')
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import(/* webpackChunkName: "subscription" */ '../views/Subscription.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "partner" */ '../views/Partner.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/press',
    name: 'press',
    component: () => import(/* webpackChunkName: "press" */ '../views/Press.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
  }
];

const router = new VueRouter({
  hashbang: false,
  mode: 'history',
  linkActiveClass: 'active',
  routes
});

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-console
  console.log(to.path);
  if (to.path === '/shop') {
    window.location.href = 'https://shop.ebikeworldfederation.com/';
    next();
  } else {
    next();
  }
});

export default router;
