<template>
  <v-app>
    <v-toolbar flat :class="[{'scrolled' : navigation.scrolled}, {'whitened': !isMember()
      && !isInsurance() && !isSubscription() && !navigation.scrolled}]">
      <v-toolbar-title>
        <img @click="goToLanding()" alt="EBWM" v-if="!isWhite() || navigation.scrolled"
             src="../public/images/EBIKE_WORLD_FEDERATION_Logo.svg">
        <img @click="goToLanding()" alt="EBWM" v-else
             src="../public/images/EBIKE_WORLD_FEDERATION_Logo_Weiss.svg">
      </v-toolbar-title>
      <v-spacer/>
      <!-- <v-toolbar-items v-if="$vuetify.breakpoint.mdAndDown">
        <v-btn icon class="menu-icon"
               @click.stop="navigation.drawer = !navigation.drawer" :ripple="false">
          <v-icon large>{{icons.menu}}</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn outlined v-if="!isLandingPage()"
               @click.prevent="goToLanding()">
          {{ $t("menu.home") }}
        </v-btn>
        <v-btn outlined v-if="isLandingPage()"
               @click.stop="closeMenu('#about')">
          {{ $t("menu.about") }}
        </v-btn>
        <v-btn outlined v-if="isLandingPage()"
               @click.stop="closeMenu('#events')">
          {{ $t("menu.events") }}
        </v-btn>
        <v-btn outlined v-if="isLandingPage()"
               @click.stop="closeMenu('#partner')">
          {{ $t("menu.partner") }}
        </v-btn>
        <v-btn outlined v-if="isLandingPage()"
               @click.stop="closeMenu('#news')">
          {{ $t("menu.news") }}
        </v-btn>
        <v-btn outlined v-if="isLandingPage() || isShop() || isMember()"
               @click.stop="closeMenu('#contact')">
          {{ $t("menu.contact") }}
        </v-btn>
        <v-btn outlined v-if="isLandingPage() || isMember()"
               @click="goToShop()">
          {{ $t("menu.shop") }}
        </v-btn>
        <v-btn v-if="isShop() && origin.available" outlined
               @click="goToSite()">
          <span v-if="origin.param === 'EBIKEWM'">
            {{$t('menu.ebikewm')}}
          </span>
          <span v-if="origin.param === 'EBIKECUP'">
            {{$t('menu.ebikecup')}}
          </span>
        </v-btn>
        <v-btn :color="isShop() && !navigation.scrolled ? 'white' : 'primary'" outlined @click="goToMember()"
               style="border: 1px solid;height: 35px !important;margin-top: 15px;">
          {{$t('menu.membership')}}
        </v-btn>
        <shop-cart v-if="isShop()"/>
        <v-select solo :items="langs" v-model="lang"/>
      </v-toolbar-items> -->
      <v-btn outlined v-if="isLandingPage() && $vuetify.breakpoint.mdAndUp" class="spread-navigation-both-ways"
             @click.stop="closeMenu('#news')">
        {{ $t("menu.news") }}
      </v-btn>
      <v-btn outlined v-if="isLandingPage() && $vuetify.breakpoint.mdAndUp" class="spread-navigation"
             @click.stop="closeMenu('#world')">
        {{ $t("menu.world") }}
      </v-btn>
      <v-btn outlined v-if="isLandingPage() && $vuetify.breakpoint.mdAndUp"
             @click.stop="closeMenu('#projects')">
        {{ $t("menu.projects") }}
      </v-btn>
      <v-select solo :items="landingDropDown" v-model="landingDropDownSelected"
                class="navigation-dropdown-menu" item-value="value" item-text="label"
                v-if="isLandingPage() && $vuetify.breakpoint.smAndDown"/>
      <v-spacer/>
      <v-toolbar-items>
        <shop-cart v-if="isShop()"/>
        <v-select solo :items="langs" v-model="lang" v-if="$vuetify.breakpoint.smAndUp"/>
        <div :class="['navigation-hamburger-menu', {'open':navigation.menu}, {'white-navigation':navigationIsWhite}]"
             @click="openNavigationMenu()">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </v-toolbar-items>
    </v-toolbar>

    <keep-alive>
      <router-view/>
    </keep-alive>

    <div style="background-color: white;position: relative;">
      <Footer/>
    </div>

    <v-dialog v-model="navigation.menu" fullscreen hide-overlay origin="top right"
              content-class="dialog-navigation-content">
      <v-card>
        <v-toolbar flat color="primary" class="dialog-navigation">
          <v-toolbar-title>
            <img @click="goToLanding()" alt="EBWM"
                 src="../public/images/EBIKE_WORLD_FEDERATION_Logo_Weiss.svg">
          </v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <shop-cart v-if="isShop()"/>
            <v-select solo :items="langs" v-model="lang"/>
            <div :class="['navigation-hamburger-menu', {'open':navigation.menu}, {'white-navigation':navigationIsWhite}]"
                 @click="closeNavigationMenu()">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-content>
            <v-row>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1">
                <a class="main-link" @click="goToLanding()">{{$t('menu.home')}}</a>
              </v-col>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1">
                <a class="main-link" @click="goToMember()">{{$t('menu.membership')}}</a>
              </v-col>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1">
                <a class="main-link" @click="goToShop()">{{$t('menu.shop')}}</a>
              </v-col>
              <v-col cols="11" offset="1" sm="12" class="pt-1 pb-1">
                <a class="main-link" @click.stop="goToPartner()">{{$t('menu.partner')}}</a>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="10" offset="1" sm="12" md="6" lg="5" xl="4">
                    <v-divider style="border-style: inset;border-width: 1px;border-color: white;"/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="10" offset="1" sm="5" class="navigation-image-container">
                <div class="inner-navigation-image-container elevation-3" @click="goToEBikeWM()">
                  <img class="navigation-image-background" alt="E-Bike WM"
                       v-lazyimg="'https://ebikeworldfederation.com/images/navigation/EBWF_Mitgliedschaft_Linkbild_EBWM_01.jpg'">
                  <v-col style="height: 100%">
                    <v-row style="z-index: 1;">
                      <v-col cols="12" class="navigation-image-title">
                        <span v-html="$t('menu.navigation.wm')"/>
                      </v-col>
                      <v-col cols="12" class="navigation-image-learn-more">
                        {{$t('learnMore')}}
                        <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                          <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                            C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                            C31.4,8.2,31.4,7.9,31.3,7.7"/>
                        </svg>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="10" offset="1" sm="5" offset-sm="0" class="navigation-image-container white-navigation-text">
                <div class="inner-navigation-image-container elevation-3" @click="goToEBikeCup()">
                  <img class="navigation-image-background" alt="E-Bike Cup"
                       v-lazyimg="'https://ebikeworldfederation.com/images/navigation/EBWF_Mitgliedschaft_Linkbild_EBCUP_01.jpg'">
                  <v-col style="height: 100%">
                    <v-row style="z-index: 1;">
                      <v-col cols="12" class="navigation-image-title">
                        <span v-html="$t('menu.navigation.cup')"/>
                      </v-col>
                      <v-col cols="12" class="navigation-image-learn-more">
                        {{$t('learnMore')}}
                        <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                          <path style="fill:white;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                            C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                            C31.4,8.2,31.4,7.9,31.3,7.7"/>
                        </svg>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-content>
        </v-container>
      </v-card>
    </v-dialog>
    <cookies/>
    <v-fab-transition>
      <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="primary" @click="toTop"
        :class="[{'member-scroll-up': isMemberAndScrollPosition()}]">
        <v-icon>{{icons.arrowUp}}</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
  /* eslint-disable no-console */
  import i18next from 'i18next';
  import { mdiMenu, mdiChevronUp, mdiClose } from '@mdi/js'

  const Footer = () => import(/* webpackChunkName: "Footer" */ './components/Footer');
  const ShopCart = () => import(/* webpackChunkName: "ShopCart" */ './components/shop/ShopCart');
  const Cookies = () => import(/* webpackChunkName: "ShopCart" */ './components/Cookies');

  export default {
    name: 'App',
    data() {
      return {
        icons: {
          menu: mdiMenu,
          arrowUp: mdiChevronUp,
          close: mdiClose,
        },
        fab: false,
        navigation: {
          scrolled: false,
          menu: false,
          position: 0,
          scrollingDown: false,
        },
        langs: ['DE', 'EN', 'IT'],
        lang: 'DE',
        origin: {
          available: false,
          link: 'https://ebikewm.com',
          param: 'EBIKEWM'
        },
        landingDropDownSelected: 1,
      };
    },
    components: {
      Footer, ShopCart, Cookies,
    },
    watch: {
      lang(language) {
        this.$store.dispatch('setLanguage', language);
        this.$vuetify.lang.current = this.$store.getters.getLanguage.toLowerCase();
        i18next.changeLanguage(this.$store.getters.getLanguage);
      },
      $route(to, from) {
        if (to.name !== from.name) {
          this.closeMenu();
        }
      },
      landingDropDownSelected(selected) {
        if (selected === 1) {
          this.closeMenu('#news');
        } else if (selected === 2) {
          this.closeMenu('#world');
        } else if (selected === 3) {
          this.closeMenu('#projects');
        }
      }
    },
    updated() {
      if (this.$route.query.origin !== undefined) {
        this.origin.param = this.$route.query.origin;
        if (this.$route.query.origin === 'EBIKEWM') {
          this.origin.link = 'https://ebikewm.com';
        } else if (this.$route.query.origin === 'EBIKECUP') {
          this.origin.link = 'https://ebikecup.com';
        }
        this.origin.available = true;
        this.$router.push({});
      }
    },
    mounted() {
      window.addEventListener('scroll', () => {
        if (!this.isIE11()) {
          this.navigation.scrolled = window.scrollY > 50;
          this.navigation.scrollingDown = this.navigation.position < window.scrollY;
          this.navigation.position = window.scrollY;
        } else {
          this.navigation.scrolled = window.pageYOffset > 50;
          this.navigation.scrollingDown = this.navigation.position < window.scrollY;
          this.navigation.position = window.pageYOffset;
        }
      });
      this.$vuetify.lang.current = this.$store.getters.getLanguage.toLowerCase();
      i18next.changeLanguage(this.$store.getters.getLanguage);
      this.lang = this.$store.getters.getLanguage;
    },
    methods: {
      isWhite() {
        return this.isShop() || this.isContact() || this.isPartner() || this.isContact() || this.isGallery()
            || this.isImpressum() || this.isPrivacy() || this.isLandingPage();
      },
      openNavigationMenu() {
        this.navigation.menu = true;
      },
      closeNavigationMenu() {
        this.navigation.menu = false;
      },
      goToSite() {
        window.open(this.origin.link, '_self');
      },
      goToEBikeWM() {
          window.open('https://ebikewm.com', '_self');
      },
      goToEBikeCup() {
        window.open('https://ebikecup.com', '_self');
      },
      onScroll() {
        if (typeof window === 'undefined') return;
        const top = window.pageYOffset || document.documentElement.offsetTop || 0;
        this.fab = top > 50;
      },
      isLandingPage() {
        return this.$route.name === 'home';
      },
      isShop() {
        return this.$route.name === 'shop';
      },
      isContact() {
        return this.$route.name === 'contact';
      },
      isPartner() {
        return this.$route.name === 'partner';
      },
      isPress() {
        return this.$route.name === 'press';
      },
      isGallery() {
        return this.$route.name === 'gallery';
      },
      isImpressum() {
        return this.$route.name === 'impressum';
      },
      isPrivacy() {
        return this.$route.name === 'privacy';
      },
      isInsurance() {
        return this.$route.name === 'insurance';
      },
      isSubscription() {
        return this.$route.name === 'subscription';
      },
      isMember() {
        return this.$route.name === 'member';
      },
      isMemberAndScrollPosition() {
        return this.isMember() && this.navigation.scrollingDown;
      },
      closeMenu(selector) {
        if (selector !== undefined && selector !== null) {
          this.globalMenuEvents.$emit('goToElement', selector);
        }
        this.closeNavigationMenu();
      },
      toTop() {
        this.$vuetify.goTo(0);
      },
      goToLanding() {
        this.closeNavigationMenu();
        if (this.$route.name !== 'home') {
          this.$router.push({ name: 'home' });
        } else {
          this.toTop();
        }
      },
      goToShop() {
        this.closeNavigationMenu();
        window.open('https://shop.ebikeworldfederation.com/', '_blank');
      },
      goToMember() {
        this.closeNavigationMenu();
        this.$router.push({ name: 'member' });
      },
      goToPartner() {
        this.closeNavigationMenu();
        this.$router.push({ name: 'partner' });
      },
      isIE11() {
        return window.navigator.userAgent.indexOf('Trident/7.0') > -1;
      },
      getLanguage() {
        return this.$store.getters.getLanguage;
      },
    },
    computed: {
      navigationIsWhite() {
        return this.navigation.menu || (this.isShop() && !this.navigation.menu && !this.navigation.scrolled);
      },
      landingDropDown() {
        return [{
          label: this.$t("menu.news"), value: 1
        }, {
          label: this.$t("menu.world"), value: 2
        }, {
          label: this.$t("menu.projects"), value: 3
        }];
      }
    }
  };
</script>

<style lang="scss">
  @import "assets/scss/variables";
  @import "assets/scss/layout";

  @font-face {
    font-family: 'Titillium Web';
    src: url('./assets/fonts/Titillium-Thin.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Titillium Web';
    src: url('./assets/fonts/Titillium-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Titillium Web';
    src: url('./assets/fonts/Titillium-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Titillium Web';
    src: url('./assets/fonts/Titillium-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Titillium Web';
    src: url('./assets/fonts/Titillium-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  #app {
    font-family: $default-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $primary-text;
    background: white;

    .v-toolbar {
      background-color: transparent;
      box-shadow: unset;
      position: fixed;
      width: 100%;
      z-index: 100;

      /* IE11 */
      @media all and (-ms-high-contrast:none)
      {
        box-shadow: none;
      }

      .v-toolbar__content {
        .v-toolbar__title {
          img {
            width: auto;
            //height: 100px;
            //top: 25px;
            //left: 30px;
            height: 70px;
            top: 5px;
            left: 0;
            position: absolute;
            cursor: pointer;
            -webkit-transition: width 0.2s ease, height 0.2s ease;
            @include MQ(M) {
              left: 5px;
            }
            @include MQ(L) {
              left: 5px;
            }
            @include MQ(XL) {
              left: 5px;
            }
          }
        }
        .v-btn {
          margin-top: 16px;
          border: none;
          text-transform: none;
          color: $primary-text;
          text-indent: 0;
          .v-btn__content {
            font-family: $default-font;
            font-weight: 400;
          }
          &.spread-navigation {
            margin-right: 80px;
            @include MQ(M) {
              margin-right: 40px;
            }
            @include MQ(L) {
              margin-right: 60px;
            }
          }
          &.spread-navigation-both-ways {
            margin-left: 80px;
            margin-right: 80px;
            @include MQ(M) {
              margin-left: 40px;
              margin-right: 40px;
            }
            @include MQ(L) {
              margin-left: 60px;
              margin-right: 60px;
            }
          }
          &:before {
            background-color: transparent;
          }
        }
        .v-toolbar__items {
          padding-top: 10px;
          padding-right: 10px;
          .v-input {
            padding-top: 8px;
            .v-input__control {
              height: 100%;
              .v-input__slot {
                max-width: 85px;
                background-color: transparent !important;
                box-shadow: unset !important;
                height: 100%;
                margin-bottom: 0;
                /* IE11 */
                @media all and (-ms-high-contrast:none)
                {
                  box-shadow: none !important;
                }
                .v-select__selections {
                  color: $primary-text;
                  font-weight: 500;
                  /* IE11 */
                  @media all and (-ms-high-contrast:none)
                  {
                    height: 31px;
                  }
                  input {
                    width: 5px;
                  }
                }
                .v-input__append-inner {
                  display: none;
                }
              }
              .v-text-field__details {
                display: none;
              }
            }
          }
        }
        .navigation-dropdown-menu {
          position: fixed;
          top: 120px;
          left: 50%;
          z-index: 101;
          transform: translateX(-50%);
          display: inline-block;
          width: 105px;
          height: 40px !important;
          font-size: 12px;
          color: white !important;
          caret-color: white !important;
          border-radius: 16px !important;
          &.header-select-tabs-navigation {
            top: 0;
            padding-top: 11px;
          }
          .v-input__slot {
            background-color: $primary;
            border-radius: 16px;
            padding-left: 8px;
          }
          .v-input__append-inner {
            padding-left: 0 !important;
          }
          .v-select__selection--comma {
            margin: 7px 0 7px 0 !important;
            text-overflow: unset !important;
            max-width: 100%;
            width: 100%;
            text-align: center;
            color: white;
          }
          .v-icon {
            color: white !important;
          }
          .v-input__slot {
            padding-left: 8px !important;
            padding-right: 4px !important;
            &::before {
              display: none !important;
              border: none !important;
            }
            &::after {
              display: none !important;
              border: none !important;
            }
          }
          .v-text-field__details {
            display: none;
          }
          .v-input__control {
            min-height: 40px;
          }
        }
      }

      .menu-icon {
        .v-icon {
          color: $primary-text;
        }
      }

      &.whitened {
        .v-toolbar__content {
          .v-btn {
            color: white;
            .v-btn__content {
              color: white !important;
            }
          }
          .v-toolbar__items {
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-select__selections {
                    color: white;
                    .v-select__selection--comma {
                      color: white;
                    }
                  }
                  .v-input__append-inner {
                    .v-input__icon {
                      svg {
                        color: white !important;
                      }
                    }
                  }
                }
              }
            }
            .navigation-hamburger-menu {
              span {
                background: white;
              }
            }
          }
        }
      }

      &.scrolled {
        background-color: white;
        z-index: 100;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
        0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
        .v-toolbar__content {
          img {
            height: 55px;
            top: 5px;
            left: 0;
            -webkit-transition: width 0.2s ease, height 0.2s ease;
            @include MQ(XS) {
              top: 0;
            }
            @include MQ(S) {
              top: 0;
            }
          }
          .v-btn {
            color: $primary-text;
            margin-top: 0;
          }
          .v-toolbar__items {
            padding-top: 0 !important;
            padding-right: 0 !important;
            .v-input {
              padding-top: 0 !important;
              .v-input__control {
                .v-input__slot {
                  .v-select__selections {
                    color: $primary-text;
                  }
                  .v-input__append-inner {
                    .v-input__icon {
                      svg {
                        color: $primary-text !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .navigation-dropdown-menu {
            top: 12px;
            margin-left: 15px;
          }
        }

        .menu-icon {
          .v-icon {
            color: $primary-text;
          }
        }
      }
    }
  }
  .v-tooltip__content {
    max-width: 500px;
    text-align: left;
    @include MQ(XL) {
      max-width: 800px;
    }
  }
  .v-list-item__content {
    text-align: left !important;
  }
  .navigation-hamburger-menu {
    width: 38px;
    height: 25px;
    position: relative;
    margin: 20px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #444444;
      border-radius: 3px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    span:nth-child(2) {
      top: 10px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    span:nth-child(3) {
      top: 20px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &.open span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 0;
      left: 0;
    }
    &.open span:nth-child(2) {
      width: 0;
      opacity: 0;
    }
    &.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 26px;
      left: 0;
  }
    &.white-navigation span {
      background: white;
    }
  }
  .dialog-navigation-content {
    .v-card {
      background-color: $primary !important;
      border-radius: 0 !important;
    }
    .dialog-navigation {
      .v-toolbar__content {
        .v-toolbar__title {
          img {
            width: 100px !important;
            height: 100% !important;
            margin-top: 15px !important;
            position: relative !important;
            top: unset !important;
            left: unset !important;
            @include MQ(XS) {
              margin-top: 25px !important;
            }
          }
        }
        .v-toolbar__items {
          padding-top: 0 !important;
          padding-right: 0 !important;
          .v-input {
            padding-top: 5px !important;
            .v-select__selection {
              color: white;
            }
          }
        }
        .ec-minicart {
          padding-top: 8px !important;
        }
      }
    }
    .container {
      padding-top: 150px;
      text-align: left;
      overflow: hidden;
      @include MQ(XS) {
        padding-top: 100px;
      }
      a {
        color: white;
        font-weight: 300;
        text-shadow: 0 3px 6px #00000029;
        letter-spacing: 0;
        &.main-link {
          font-size: 35px;
          @include MQ(XS) {
            font-size: 25px;
          }
          @include MQ(S) {
            font-size: 30px;
            line-height: 35px;
          }
        }
        &.sub-link {
          font-size: 25px;
          line-height: 30px;
          @include MQ(XS) {
            font-size: 20px;
          }
        }
      }
      .navigation-image-container {
        height: 200px;
        border-radius: 8px;
        color: #444444;
        @include MQ(XS) {
          height: 120px;
        }
        &.white-navigation-text {
          color: white;
        }
        .inner-navigation-image-container {
          border-radius: 8px;
          height: 100%;
          position: relative;
          cursor: pointer;
          .navigation-image-background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            position: absolute;
            z-index: 0;
          }
          .navigation-image-title {
            font-weight: 600;
            text-shadow: 0 3px 6px #00000029;
            letter-spacing: 0;
            font-size: 35px;
            line-height: 35px;
            position: relative;
            @include MQ(XS) {
              font-size: 20px;
              line-height: 20px;
            }
            @include MQ(S) {
              font-size: 30px;
              line-height: 30px;
            }
          }
          .navigation-image-learn-more {
            font-size: 16px;
            font-style: italic;
            font-weight: 600;
            position: absolute;
            bottom: 5px;
            @include MQ(XS) {
              bottom: 0;
            }
            svg {
              height: 12px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  .member-scroll-up {
    bottom: 65px !important;
    @include MQ(XS) {
      bottom: 90px !important;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }
</style>
